export default {
    methods: {
        allowDrop(draggingNode, dropNode, type) {
            // console.log('draggingNode', draggingNode)
            // 最顶层的不能放置，只能在最顶层之内放置
            if (dropNode.data.id === this.topItemId) {
                return type === 'inner';
            } else {
                return true;
            }
        },
        allowDrag(draggingNode) {
            // 最顶层的那个不能拖动
            return draggingNode.data.id !== this.topItemId
        },
        handleDrop(draggingNode, dropNode, dropType) {
            // 拖拽完成并成功
            // console.log('tree drop: ', draggingNode, dropNode, dropType);
            let curretnNodeId = draggingNode.data.id
            let node = {
                parentId: null,
                name: "",
                seq: 1
            }
            if (dropType === 'inner') {
                node.name = draggingNode.data.name
                node.seq = dropNode.data.seq
                node.parentId = dropNode.data.id
            } else {
                node.name = draggingNode.data.name
                node.seq = dropNode.data.seq
                node.parentId = dropNode.parent.data.id
            }
            this.putEditItem(curretnNodeId, node);
        },
    }
}